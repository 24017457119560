import React from 'react'
// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';

const about = () => {
  return (
    <div className='w-full mt-72 lg:py-10 pt-64 '>
        <motion.div
        variants={fadeIn("up", 0.2)}
        initial="hidden"
        whileInView={"show"}
        viewport={{once: false, amount: 0.7}} 

        className='max-w-[1240px] mx-auto'>
            <div className='text-center'>
                <h2 className='text-4xl font-bold'>Truck Repair Services Trusted By Truck Driver</h2>
                <p className='text-2xl py-6 text-gray-500 text-justify mx-5'>Save time and money & get repairs without waiting or
                having to tow your truck to a shop. 95% of our truck service 
                repairs are completed on-site within hours.</p>
            </div>
            {/*Targets about tha Service*/}
            <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                <motion.div
                variants={fadeIn("rigth", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='border py-8 rounded-xl shadow-xl'>
                    <p className='text-5xl font-bold text-red-700'>100%</p>
                    <p className='text-gray-400 mt-2'>Quality Work</p>
                </motion.div>
                <motion.div
                variants={fadeIn("rigth", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='border py-8 rounded-xl shadow-xl'>
                    <p className='text-5xl font-bold text-red-700'>24/7</p>
                    <p className='text-gray-400 mt-2'>Roadside Assistance</p>
                </motion.div>
                <motion.div
                variants={fadeIn("rigth", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='border py-8 rounded-xl shadow-xl'>
                    <p className='text-gray-400'>Over</p>
                    <p className='text-5xl font-bold text-red-700'>1,000</p>
                    <p className='text-gray-400 mt-2'>Services Completed</p>
                </motion.div>
            </div>
        </motion.div>


        
    </div>
  )
}

export default about