import React from 'react'
import {CheckIcon} from '@heroicons/react/solid'

// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';


import LogoFM from '../assets/LogoFM.jpg'

const AboutUs = () => {
  return (
    <div name='AboutUs' className='w-full text-white my-24'>
      <div className='w-full h-[800px] bg-black absolute mix-blend-overlay'></div>

      <div className='max-w-[1240px] mx-auto py-12'>

        <div className='text-center py-8 text-slate-300'>
          <motion.div
          variants={fadeIn("rigth", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.7}} 
          >
            <h2 className='text-2xl uppercase text-bold'>Flores Mobile</h2>
          </motion.div>
          <motion.div
          variants={fadeIn("left", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{once: false, amount: 0.7}} 
          >
          <h3 className='text-3xl font-bold text-white py-8'>Diesel Mechanic & Heavy Duty LLC</h3>
          </motion.div>
          
          <p className='text-xl px-3'>
          We are the most complete diesel truck repair service in Indianapolis.
          We take great pride in being your first choice for mobile roadside assistance. 
          Our customers are our priority, so we make sure we are always available when 
          you need us.
          </p>
        </div>

        <div className='lg:w-[50%] mx-auto grid md:grid-cols-1'>

          <div className='bg-white text-slate-900 m-4 p-8 rounded-xl shadow-2xl relative'>
            <motion.div 
            variants={fadeIn("down", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.7}} 
            >
              <span className='lg:ml-40 ml-14 px-3 py-1 bg-amber-200 text-amber-900 rounded-2xl text-sm'>Extensive Indianapolis Coverage</span>
                <div>
                  <p className='text-6xl font-bold py-4 flex'><span className='text-xl text-slate-500 flex flex-col justify-start'>Service 24/7</span> </p>
                  <span className='text-xl text-red-700 flex flex-col justify-end'>Ph: +1(317) 625-2257</span>
                </div>
                <p className='text-2xl py-8 text-slate-500 text-justify'>
                With our fast response times for roadside assistance, you can't afford not to add our hotline to your contacts.
                And check out our benefits
                </p>
            </motion.div>
              
            <motion.div 
            variants={fadeIn("rigth", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.7}} 
            className='text-xl'>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-red-700'  />Fast responses and assistance</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-red-700'  />We detect problems quickly</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-red-700'  />Repairs On Site</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-red-700'  />Quality work guaranteed</p>
                <p className='flex py-4'><CheckIcon className='w-8 mr-5 text-red-700'  />Affordable Prices</p>
                <a href="tel:+13176252257">
                  <button className='w-full py-4 my-4'>Call Us Now</button>
                </a>
            </motion.div>
          </div>
          
        </div>
      </div>
      {/*Logo Flores Mobile */}
      <motion.div 
      variants={fadeIn("up", 0.2)}
      initial="hidden"
      whileInView={"show"}
      viewport={{once: false, amount: 0.7}} 
      className='py-10'>
      <img className='lg:w-[50%] mx-auto w-full' src={LogoFM} alt="/" />
      </motion.div>
    </div>
  )
}

export default AboutUs