
import React from 'react';

// motion
import { motion } from "framer-motion";
// variants
import { fadeIn } from "../variants";

//Images
import blog4 from "../assets/blog4.png";
import blog5 from "../assets/blog5.png";
import blog6 from "../assets/blog6.png";

const BlogTown = () => {
    
    return (
        <div  className='px-4 lg:px-14 max-w-screen-2xl mx-auto my-12' id='faq'>
            <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: false, amount: 0.6 }}
            
            className='text-center md:w-1/2 mx-auto'>
            <h2 className="text-4xl font-bold">
            Some our towing service
            </h2>
            </motion.div> 

            <div className="px-4 lg:px-14 max-w-screen-2xl mx-auto my-8" id="about">
                <div className="md:w-11/12 mx-auto flex flex-col md:flex-row justify-between items-center gap-12">
                    <motion.div
                        variants={fadeIn("up", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.6 }}
                    >
                        <img src={blog4} alt="" className="w-full rounded-full" />
                    </motion.div>

                    <motion.div
                        variants={fadeIn("up", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.6 }}
                    >
                        <img src={blog5} alt="" className="w-full rounded-full" />
                    </motion.div>

                    <motion.div
                        variants={fadeIn("up", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{ once: false, amount: 0.6 }}
                    >
                        <img src={blog6} alt="" className="w-full h-max rounded-full" />
                    </motion.div>

                    
                </div>
            </div>

        </div>
    );
};

export default BlogTown;