import Presentation from '../components/Presentation';
import About from '../components/About';
import Support from '../components/Support';
import AllServices from '../components/AllServices';
import AboutUs from '../components/AboutUs';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import Blog   from '../components/Blog'

const Home = () =>{


    return (
        <>  
            <Navbar/>
            <Presentation />
            <About/>
            <Blog/>
            <Support/>
            <AllServices/>
            <AboutUs/>
            <Footer/>
        </>
        
    )
}

export default Home;