import PresentationTown from '../components/PresentationTown';
import AboutTown from '../components/AboutTown';
import Support from '../components/Support';
import AboutUs from '../components/AboutUs';
import Footer from '../components/Footer';
import NavbarTown from '../components/NavbarTown';
import BlogTown from '../components/BlogTown';

const TowingService = () =>{


    return (
        <>
            <NavbarTown/>
            <PresentationTown/>
            <AboutTown/>
            <BlogTown/>
            <Support/>
            <AboutUs/>
            <Footer/>
        </>
    )


}

export default TowingService;