import React from 'react';
// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';

import {
    DesktopComputerIcon,
    AdjustmentsIcon,
    LightningBoltIcon,
    SupportIcon,
    BadgeCheckIcon,
    ClipboardCheckIcon

} from '@heroicons/react/solid'



const AllServices = () => {
  return (
    <div name='TruckServices' className='w-full my-20'>
        <div className='max-w-[1240px] mx-auto px-2'>
            <motion.div 
            variants={fadeIn("rigth", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.7}} 
            >
            <h2 className='text-3xl text-center font-bold text-red-700'>All Truck Services On Site</h2>
            </motion.div>
            
            <p className='text-xl mx-3 py-8 text-gray-800 text-justify'>
            Our full coverage in Indianapolis offers a full-service mobile 
            shop with immediate availability.
            </p>
            <p className='text-xl mx-3 py-1 text-gray-800 text-justify'>We're ready to maintain or repair your commercial vehicle and 
            get you back on the road. We remain on call 24/7 for after-hours 
            emergencies.</p>

            {/*Structure Card of descripcion services*/}
            <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-4 pt-4'>
                {/*Services 1*/}
                <motion.div
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 

                className='flex'>
                    <div>
                        <DesktopComputerIcon className='w-7 mr-4 text-red-700'/>    
                    </div>
                    <div>
                        <h3 className='text-bold text-lg font-bold'>Full Diagnostic (By scanner)</h3>
                        <p className='text-lg pr-3 pt-2 pb-4 text-justify'>
                        Repairs and maintenance provided by Flores Mobile utilize the latest diagnostic 
                        tools and technologies, operated by professional mechanics who constantly specialize, 
                        so you can rely on our truck repair services.
                        </p>
                    </div>
                </motion.div>
                {/*Services 2*/}
                <motion.div 
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 

                className='flex'>
                    <div>
                        <LightningBoltIcon className='w-7 mr-4 text-red-700'/>    
                    </div>
                    <div>
                        <h3 className='text-bold text-lg font-bold'> Electrical Repair</h3>
                        <p className='text-lg pt-2 pb-4 text-justify pr-3'>
                        We perform light electrical repairs and diagnostics on all trucks including 
                        headlights, marker lights, batteries, we replace starters and  other starting issues.
                        </p>
                    </div>
                </motion.div>
                {/*Services 3*/}
                <motion.div 
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='flex'>
                    <div>
                        <SupportIcon className='w-7 mr-4 text-red-700'/>    
                    </div>
                    <div>
                        <h3 className='text-bold text-lg font-bold'> Tire Repair & Brake Replacement</h3>
                        <p className='text-lg pt-2 pb-4 text-justify pr-3'>
                        Flores Mobile inspects, adjusts or replaces hydraulic and air brakes on all 
                        makes and models of trucks and trailers. We replace your bad tire and to top 
                        it off! We also inspect brakes, check drums, bearings and solve any problems 
                        that arise.
                        </p>
                    </div>
                </motion.div>
                {/*Services 4*/}
                <motion.div 
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='flex'>
                    <div>
                        <ClipboardCheckIcon className='w-7 mr-4 text-red-700'/>    
                    </div>
                    <div>
                        <h3 className='text-bold text-lg font-bold'>Oil Change</h3>
                        <p className='text-lg pt-2 pb-4 text-justify pr-3'>
                        If you have neglected to maintain your engine or are within 
                        the recommended time interval for an oil change, it is the 
                        ideal time to schedule our service as soon as possible to 
                        avoid engine contamination or loss of power.
                        </p>
                    </div>
                </motion.div>
                {/*Services 6*/}
                <motion.div 
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='flex'>
                    <div>
                        <AdjustmentsIcon className='w-7 mr-4 text-red-700'/>    
                    </div>
                    <div>
                        <h3 className='text-bold text-lg font-bold'>Complete Engine Repair</h3>
                        <p className='text-lg pt-2 pb-4 text-justify pr-3'>
                        Covering all the common services you would expect from a professional 
                        mobile repair shop, we offer diesel engine repair, electrical systems, 
                        differentials, overhaul, brakes, diagnostics, fuel systems and much 
                        more. 
                        </p>
                        <p className='text-lg pt-2 pb-4 font-bold text-justify pr-3'>
                        Our efficient service minimizes the downtime of your commercial 
                        truck and keeps it in good condition for longer.
                        </p>
                    </div>
                </motion.div>
                {/*Services 6*/}
                <motion.div 
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='flex'>
                    <div>
                        <BadgeCheckIcon className='w-7 mr-4 text-red-700'/>    
                    </div>
                    <div>
                        <h3 className='text-bold text-lg font-bold'>DOT inspections</h3>
                        <p className='text-lg pt-2 pb-4 text-justify pr-3'>
                        We perform complete truck and trailer inspections.  Avoid potential 
                        accidents or costly fines and downtime by keeping your commercial 
                        vehicle in top condition with our preventive maintenance.
                        </p>
                    </div>
                </motion.div>
            
            </div>
        </div>
    </div>
  )
}

export default AllServices