import React from 'react'

import supportImg from '../assets/support.jpg'
// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';
import {
    PhoneIcon,
    ArrowSmRightIcon,

} from '@heroicons/react/outline'

import {

    TruckIcon,
    UserGroupIcon

} from '@heroicons/react/solid'




const Support = () => {
  return (
    <div name='support' className=' w-full'>
        <div className='w-full h-[700px] bg-gray-900/90 absolute'>
            <img className='w-full h-full object-cover mix-blend-overlay' src={supportImg} alt="/" />
        </div>

        <div className=' max-w-[1240px] mx-auto text-white relative'>
            <motion.div 
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.7}} 
            
            className='px-4 py-12'>
                <h2 className='text-3xl pt-8 text-slate-300 uppercase text-center'>Support</h2>
                <h3 className='text-3xl font-bold py-6 text-center'>Heavy Duty Services</h3>
                <p className='py-4 text-2xl text-slate-300  mx-3' >
                We are the most complete diesel truck repair service in Indianapolis, 
                and surrounding area
                </p>
                <p className='py-4 text-2xl text-slate-300 text-justify mx-3'>We care about providing you with quality service and getting you back 
                on the road.</p>
            </motion.div>
            {/*Structure for target Sales*/}
            <div className=' grid grid-cols-1 lg:grid-cols-3 relative gap-x-8 gap-y-16 px-4 pt-12 sm:pt-20 text-black'>
                
                {/*Structure for target 1*/}
                <motion.div
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='bg-white rounded-xl shadow-2xl'>
                    <div className='p-8'>
                        <PhoneIcon className='w-16 p-4 bg-red-700 text-white rounded-lg mt-[-4rem]' />
                        <h3 className='font-bold text-2xl my-6'>Customer Service</h3>
                        <p className='text-gray-600 text-xl text-justify'>
                        Our dispatchers will send the mechanic closest to you and
                        give you the service you deserve at the best price and quality in the 
                        area.</p>
                    </div>
                    <div className='bg-slate-100 pl-8 py-4'>
                        <a href="tel:+13176252257">
                            <p className='flex items-center text-red-700'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p>
                        </a>
                    </div>
                </motion.div>
                {/*Structure for target 2*/}
                <motion.div
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='bg-white rounded-xl shadow-2xl'>
                    <div className='p-8'>
                        <UserGroupIcon className='w-16 p-4 bg-red-700 text-white rounded-lg mt-[-4rem]' />
                        <h3 className='font-bold text-2xl my-6'>Truck Mechanics</h3>
                        <p className='text-gray-600 text-xl text-justify mb-1'>
                        We have qualified mechanics near you available anytime you need them 24/7.  
                        Don't wait any longer and get help faster
                        </p>
                    </div>
                    <div className='bg-slate-100 pl-8 py-4'>
                        <a href="tel:+13176252257">
                        <p className='flex items-center text-red-700'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p>
                        </a>
                    </div>
                </motion.div>
                {/*Structure for target 3*/}
                <motion.div
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}} 
                
                className='bg-white rounded-xl shadow-2xl'>
                    <div className='p-8'>
                        <TruckIcon className='w-16 p-4 bg-red-700 text-white rounded-lg mt-[-4rem]' />
                        <h3 className='font-bold text-2xl my-6'>Quick Repairs</h3>
                        <p className='text-gray-600 text-xl text-justify'>
                        Our service vehicles are immediately available to assist you. All equipped with the 
                        tools needed for common truck repairs. 
                        </p>
                    </div>
                    <div className='bg-slate-100 pl-8 py-4'>
                        <a href="tel:+13176252257">
                        <p className='flex items-center text-red-700'>Contact Us <ArrowSmRightIcon className='w-5 ml-2' /></p>
                        </a>
                        
                    </div>
                </motion.div>
                {/*End Targets Support*/}
            </div>
        </div>
    </div>
  )
}

export default Support

