import React, {useState} from 'react';
import { Link as LinkMain } from 'react-router-dom';
import { Link  } from 'react-scroll'

import { MenuIcon,
        XIcon,
        PhoneIcon,
        ArrowDownIcon
        } from '@heroicons/react/outline'

import logoPrincipal from '../assets/Logo1.png'

const Navbar = () => {

  const [nav, setNav] = useState(false)
  const handleClick = () => setNav(!nav)

  const handleClose = () => setNav(!nav)

  return (
    <div className='w-screen h-[80px] z-10 bg-black text-zinc-200 fixed drop-shadow-2xl'>
      <div className='px-2 flex justify-between items-center w-full h-full'>
        <div className='flex items-center'>
          {/*Link To move to the top section of the page */}
          <Link to="home" smooth={true}  duration={500} >
            {/*LinkMain to move other page  */}
            <LinkMain to={"/"}>
              <div className='flex'>
                <img className='w-10' src={logoPrincipal} alt="/" />
                <h1 className='mt-2 ml-6 text-2xl font-bold lg:mr-52 md:text-2xl sm:text-4xl'>Flores Mobile Services</h1>
              </div>           
            </LinkMain>
          </Link> 
          {/* md:flex It means that when displayed on screen small < 640px it will be hidden. */}
          <ul className='hidden md:flex md:text-1xl'>

          <li><Link  to="home" smooth={true}  duration={500} >Home</Link></li>
          <li className='text-red-500 '>
            <Link to="home" smooth={true} duration={500}>
              <LinkMain to={"/"}>
                Truck Service
              </LinkMain>
            </Link>
          </li>
          <li ><Link  to="support" smooth={true} offset={-40} duration={500} >Contact us</Link></li>
          <li><Link  to="TruckServices" smooth={true} offset={-90} duration={500} >Services</Link></li>
          <li><Link  to="AboutUs" smooth={true} offset={-50} duration={500} >About us</Link></li>
            
          </ul>
        </div>

        {/* Buttons */}
        <div className='hidden md:flex pr-4'>
          <p className='py-3 font-bold text-zinc-200 mr-4'>Quick Assistance Call</p>
          <a href="tel:+13176252257">
            <button className='px-8 py-3'>+1(317) 625-2257</button>
          </a>
        </div>
        {/* Menu Icon*/}
        <div className='md:hidden mr-4' onClick={handleClick}>
          {!nav ? <MenuIcon className='w-5  text-red-500' /> : <XIcon className='w-6 text-red-700' /> }
          
        </div>
      </div>
      <ul className={!nav ? 'hidden' : 'absolute bg-black w-full px-6' }>

          <li className='border-b-2 border-zinc-300 w-full'>                 <Link onClick={handleClose}  to="home" smooth={true}  duration={500} >Home</Link></li>
          <li className='text-red-500 border-b-2 border-zinc-300 w-full'>
            <Link onClick={handleClose} to="home" smooth={true} duration={500}>
              <LinkMain to={"/"}>
                Truck Service
              </LinkMain>
            </Link>
          </li>
          <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose}  to="support" smooth={true} offset={-40} duration={500} >Contact us</Link></li>
          <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose}  to="TruckServices" smooth={true} offset={-90} duration={500} >Services</Link></li>
          <li className='border-b-2 border-zinc-300 w-full'><Link onClick={handleClose}  to="AboutUs" smooth={true} offset={-50} duration={500} >About us</Link></li>

        <div className='flex flex-col my-4'>
          <a href="tel:+13176252257" className='max-md:w-[40%] max-md:w-[80%] py-3 px-5 sm:w-[100%] my-4
                  rounded inline-flex items-center bg-transparent text-red-500 mb-4 font-bold'>
                      <ArrowDownIcon className='w-4 h-4 mr-2' /> 
                        To get mobile truck repair near you 
                      <ArrowDownIcon className='w-4 h-4 ml-2' />
          </a>
          <a href="tel:+13176252257">
              <button className='max-md:w-[40%] max-md:w-[80%] py-3 px-5 sm:w-[100%] my-4  rounded inline-flex items-center'>
                      <PhoneIcon className='w-4 h-4 mr-2' /> <span>Call Now</span>
              </button>
          </a>
        </div>
        
      </ul>

      
    </div>
  )
}

export default Navbar