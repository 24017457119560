import React from 'react'

import Home from "./Screens/Home";
import TowingService from "./Screens/TowingService";
import NotFound from "./Screens/NotFound";

import { Routes, Route} from "react-router-dom";

export const App = () => {
  return (
    <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/TowingServices" element={<TowingService />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
    </div>
  );
};