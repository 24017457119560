import React from 'react'
// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';
import Assistance247 from '../assets/24h.png'
import certified from '../assets/certified.png'
import Fast from '../assets/Fast.png'

const about = () => {
  return (
    <div className='w-full my-20 lg:py-10 pt-72'>
        <div className='max-w-[1240px] mx-auto'>
            <motion.div 
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{once: false, amount: 0.7}}
            
            className='text-center'>
                <h2 className='text-4xl font-bold'>Truck Towing  Services Trusted By Truck Driver</h2>
                <p className='text-2xl py-6 text-gray-500 text-justify mx-5'>
                    We offer 24 hour heavy truck towing and emergency heavy duty towing services in Indiana.
                    With a huge fleet of towing and recovery equipment, we can be at your location within minutes.</p>
            </motion.div>
            {/*Targets about tha Service*/}
            <div className='grid md:grid-cols-3 gap-1 px-2 text-center'>
                <motion.div 
                variants={fadeIn("rigth", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}
                className='border py-8 rounded-xl shadow-xl'>
                    <img className='ml-24 w-1/2  ' src={certified} alt="/" />
                    <p className='text-stone-950 mt-2'><b>CERTIFIED EXPERTS</b> </p>
                </motion.div>
                <motion.div 
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}
                
                className='border py-8 rounded-xl shadow-xl'>
                <img className='ml-24 w-1/2  ' src={Assistance247} alt="/" />
                    <p className='text-stone-950 mt-2'><b>Assistance 24/7</b> </p>
                </motion.div>
                <motion.div 
                variants={fadeIn("rigth", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{once: false, amount: 0.7}}

                className='border py-8 rounded-xl shadow-xl'>
                <img className='ml-20 w-1/2  ' src={Fast} alt="/" />
                    <p className='text-stone-950 mt-2'><b>FAST & QUALITY SERVICE</b> </p>
                </motion.div>
            </div>
        </div>
    </div>
  )
}

export default about