import React from 'react'

import {
    FaFacebook,
    FaInstagram,
    FaTwitter,
} from 'react-icons/fa'

const Footer = () => {
  return (
    <div className='w-full mt-24 bg-black text-gray-300 py-y px-2'>
        <div className='max-w-[1240px] mx-auto grid grid-cols-2 md:grid-cols-6 border-b-2 border-gray-600 py-8'>
            <div>
                <h6 className='font-bold uppercase pt-2 text-red-700'>Truck Services</h6>
                <ul>
                    <li className='py-1'>Full Diagnostic (By scanner)</li>
                    <li className='py-1'>Electrical Repair</li>
                    <li className='py-1'>Complete Engine Repair</li>
                    <li className='py-1'>Lockouts & jumpstarts</li>
                    <li className='py-1'>Hydraulic repair</li>
                    
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2 text-red-700'>DOT inspections</h6>
                <ul>
                    <li className='py-1'>Brake Replacement</li>
                    <li className='py-1'>Air Brakes</li>
                    <li className='py-1'>Wheel Alignment</li>
                    <li className='py-1'>Oil Change</li>
                    <li className='py-1'>Battery Replacement</li> 
                    
                </ul>
            </div>
            <div>
                <h6 className='font-bold uppercase pt-2 text-red-700'>Company</h6>
                <ul>
                    <li className='py-1'>About</li>
                    <li className='py-1'>Blog</li>
                    <li className='py-1'>Jobs</li>
                </ul>
            </div>
            <div>
                {/*<h6 className='font-bold uppercase pt-2'>Legal</h6>
                <ul>
                    <li className='py-1'>Claims</li>
                    <li className='py-1'>Privacy</li>
                    <li className='py-1'>Terms</li>
                    <li className='py-1'>Policies</li>
                    <li className='py-1'>Conditions</li>
                </ul>*/}
            </div>
            <div className='col-span-2 pt-8 md:pt-2'>
                <p className='font-bold uppercase'>Subscribe to our newsletter</p>
                <p className='py-4'>Receive the best deals on our truck care services, delivered weekly to your inbox.</p>
                <form className='flex flex-col sm:flex-row'>
                    <input className='w-full p-2 mr-4 rounded-md mb-4' type="email" placeholder='Enter your email..'/>
                    <button className='p-2 mb-4'>Subscribe</button>
                </form>
            </div>
        </div>

        <div className='flex flex-col max-w-[1240px] px-2 py-4 mx-auto justify-between sm:flex-row text-center text-gray-500'>
        <p className='py-4'>2024 Flores Mobile Diesel Mechanic & Heavy Duty LLC. All rights reserved</p>
        <div className='flex justify-between sm:w-[300px] pt-4 text-2xl px-24'>
            <FaFacebook />
            <FaInstagram />
            <FaTwitter />
        </div>
        </div>
    </div>
  )
}

export default Footer