import React from 'react'
// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';


import {
    PhoneIcon,
    DesktopComputerIcon,
    AdjustmentsIcon,
    LightningBoltIcon,
    SupportIcon,
    ShieldCheckIcon,
    BadgeCheckIcon,

} from '@heroicons/react/solid'

import TruckFM from '../assets/TruckBanner.jpg'

const Presentation = () => {
    return (
        <div>
            <div name='home' className='py-16  w-full h-screen bg-zinc-200 flex flex-col justify-between pb-11 '>
                <div className='grid md:grid-cols-2 max-w-[1240px] m-auto lg:py-20  md:mb-1'>
                    <motion.div 
                        variants={fadeIn("rigth", 0.2)}
                        initial="hidden"
                        whileInView={"show"}
                        viewport={{once: false, amount: 0.7}}
                        
                        className='flex flex-col justify-center md:items-start w-full px-2 py-10'>
                        <p className='text-2xl text-red-700 font-bold'>Heavy Truck Repair & Service</p>
                        <h1 className='py-3 text-5xl md:text-7xl font-bold'>Roadside Assistance</h1>
                        <p className='text-2xl'>Get help in minutes </p>
                        <a href="tel:+13176252257">
                            <button className='max-md:w-[40%] max-md:w-[80%] py-3 px-5 sm:w-[100%] my-4  rounded inline-flex items-center'>
                                <PhoneIcon className='w-4 h-4 mr-2' /> <span>Call Now</span>
                            </button>
                        </a>
                    </motion.div>

                    <motion.div
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  
                    >
                        <img className='rounded-[40px] w-full' src={TruckFM} alt="/" /> 
                    </motion.div>

                </div>

            </div>
            <div className=' absolute flex flex-col  md:min-w-[760px] bottom-[1%]
                                mx-1   md:left-1/2 transform translate-y-[150%] lg:translate-y-60 md:-translate-y-10 md:-translate-x-1/2 bg-zinc-300
                                border border-slate-300 rounded-xl text-center shadow-xl'>
                    <p className='font-bold pt-3 pb-3 text-xl'>Truck Repair Solutions  That Go The Extra Mile</p>
                    <motion.div
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}  

                    className='flex justify-between flex-wrap px-4'>
                        <p className='flex px-4 py-2 text-slate-500'><DesktopComputerIcon className='h-6 text-red-700' />Full Diagnostic (By scanner)</p>
                        <p className='flex px-4 py-2 text-slate-500'><AdjustmentsIcon className='h-6 text-red-700' />Complete Engine Repair</p>
                        <p className='flex px-4 py-2 text-slate-500'><LightningBoltIcon className='h-6 text-red-700' /> Electrical Repair</p>
                        <p className='flex px-4 py-2 text-slate-500'><SupportIcon className='h-6 text-red-700' /> Tire Repair & Replacement</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Lockouts & jumpstarts</p>
                        <p className='flex px-4 py-2 text-slate-500'><BadgeCheckIcon className='h-6 text-red-700' /> DOT inspections</p>
                    </motion.div>
            </div>
        </div>
        
    )
}

export default Presentation