import React from 'react'
// motion
import {motion} from 'framer-motion';
// variants for effects framer motion 
import {fadeIn} from '../variants';
import {
    PhoneIcon,
    ShieldCheckIcon,

} from '@heroicons/react/solid'

import TruckTown from '../assets/TruckTown.png'

const Presentation = () => {
    return (
        <div>
            <div name='home' className='py-16 w-full h-screen bg-zinc-200 flex flex-col justify-between pb-1'>
                <div className='grid md:grid-cols-2 max-w-[1240px] m-auto lg:py-20  md:mb-1'>
                    <motion.div 
                    variants={fadeIn("rigth", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}

                    className='flex flex-col justify-center md:items-start w-full px-2 py-10'>
                        <p className='text-2xl text-red-700 font-bold '>Heavy Duty Towing Service</p>
                        <h1 className='py-3 text-4xl md:text-5xl font-bold '>TRUCK TOW SERVICE IN INDIANAPOLIS</h1>
                        <p className='text-2xl'>Get help in minutes. WE HAUL BIG OR SMALL. </p>
                        <a href="tel:+13176252257">
                            <button className='max-md:w-[40%] max-md:w-[80%] py-3 px-5 sm:w-[100%] my-4  rounded inline-flex items-center'>
                                <PhoneIcon className=' w-4 h-4 mr-2' /> <span>Call Now</span>
                            </button>
                        </a>
                    </motion.div>
                    <motion.div 
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    className='mt-2'>
                        <img className='w-full' src={TruckTown} alt="/" />
                    </motion.div>

                </div>

            </div>
            <div className=' absolute flex flex-col  md:min-w-[760px] bottom-[1%]
                                mx-1   md:left-1/2 transform translate-y-96 sm:translate-y-96 lg:translate-y-24 md:-translate-y-1 md:-translate-x-1/2 bg-zinc-300
                                border border-slate-300 rounded-xl text-center shadow-xl'>
                    <motion.div 
                    variants={fadeIn("rigth", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}
                    
                    >
                    <p className='font-bold pt-3 pb-3 text-xl'>Ditch the online search! Call us for Indianapolis' #1 towing service, 
                        trusted by your neighborhood for fast, reliable assistance.</p>
                    </motion.div>
                    
                    <motion.div 
                    variants={fadeIn("left", 0.2)}
                    initial="hidden"
                    whileInView={"show"}
                    viewport={{once: false, amount: 0.7}}

                    className='flex justify-between flex-wrap px-4'>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Truck Towing</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Flatbed Towing</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Light-Duty Towing</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Wrecker Towing</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Box Truck Towing</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Auto Transports</p>
                        <p className='flex px-4 py-2 text-slate-500'><ShieldCheckIcon className='h-6 text-red-700' /> Luxury Car Towing</p>
                    </motion.div>
            </div>
        </div>
        
    )
}

export default Presentation